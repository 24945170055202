import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import IconEnvelope from "../../static/icons/envelope-open.svg";
import HubspotForm from "react-hubspot-form"

const NewsletterPage = () => (
  <Layout>
    <Seo title="Contact" description="You wanna join the ECO-Qube project or have any questions? So feel free to contact us and let's talk." />
    <div className="max-w-screen-md mx-auto text-center">
      <div className="inline-flex justify-center items-center">
        <div className="relative inline-block mr-6">
          <img src={'../cube-skelleton.svg'} className="w-16" />
          <IconEnvelope className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-1" />
        </div>
        <h2 className="text-4xl lg:text-5xl font-bold font-display text-center">Newsletter</h2>
      </div>
      <p className="mt-4 text-center text-2xl">Once a year, the big info is bundled into your mailbox.</p>
    </div>
    <div className="max-w-screen-md mx-auto px-4 relative z-20 mt-20 pb-96">
      <form className="mt-12">
        <label htmlFor="email" className="font-bold">E-Mail Address</label>
        <div className="">
          <HubspotForm
            portalId='7577247'
            formId='690fc6af-a95e-498e-8dc2-48d5a59fbf2c'
            loading={<div>Loading...</div>}
            submitButtonClass={'rounded-md flex-shrink-0 bg-gradient-to-tr from-secondary-base to-secondary-700 text-white font-bold py-2 px-4 rounded-md-r inline-block'}
          />
        </div>
      </form>
    </div>
    
  </Layout>
)

export default NewsletterPage
